<!-- <button class="btn close-menu" [hidden]="!closed_menu" (click)="closeMenu()" type="button" *ngIf="closed_menu">
    <i class="fa fa-bars fa-2x"></i>
</button> -->
<!-- <button class="btn close-menu" [hidden]="!closed_menu" (click)="closeMenu()" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fa fa-bars fa-2x"></i>
</button> -->
<div class="menu-container " (mouseenter)="handleMouseEnterMenu()"  (mouseleave)="handleMouseLeaveMenu()">
    <div class="collapse show background-menu" id="sidebar">
        <!-- <button id="close_menu_button" class="btn close-menu-arrow" (click)="closeMenu()" type="button" aria-label="closebutton">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button> -->
        <a class="navbar-brand" (click)="openPage('/')" style="cursor: pointer;">
            <img class="light-logo" [src]="lightLogoRoute" alt="">
        </a>
        <ul class="nav flex-column flex-nowrap overflow-hidden nav-content menu-items">
            <li class="nav-item">
                <a class="nav-link text-truncate" (click)="openPage('/')" *ngIf="config.menu.panel">
                    <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/panelWhite.png" alt="">
                    <img class="green-icon" src="../../../assets/resources/icons/panelGreen.png" alt="">
                    <span  *ngIf="mouseInContainer"class="d-sm-inline">Panel</span>
                </a>
            </li>
            <div class="scrollable-menu">
                <li  class="nav-item" *ngIf="config.menu?.documentos"><!--  *ngIf="Permissions.documents_control" -->
                    <a *ngIf="config.menu?.documentos" class="nav-link collapsed text-truncate" href="#document-select" data-toggle="collapse" data-target="#document-select">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/ControlWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/ControlGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline hidden">Documentos</span><i class="fa fa-angle-down hidden" aria-hidden="true"></i>
                    </a>
                    <div class="collapse expandable-menu" id="document-select" aria-expanded="false">
                        <ul class="flex-column nav sub-item">
                            <li *ngIf="(permissions['documentos.crear_documentos_internos'] || permissions['documentos.crear_documentos_externos']) && config.menu.documentos.create" class="nav-item"><a class="nav-link" (click)="openPage('/document/create')"><span>Crear</span></a></li>
                            <!-- <li class="nav-item"><a class="nav-link" (click)="openPage('/document/folder')"><span>SIGCI</span></a></li> -->
                            <li *ngIf="config.menu.documentos.SIGCI" class="nav-item"><a class="nav-link" (click)="openPage('/document/folder')"><span>{{config.menu.documentos.text_SIGCI}}</span></a></li>
                            <li *ngIf="isAdmin() && config.menu.documentos.templates" class="nav-item"><a class="nav-link" (click)="openPage('/document/template-dashboard')"><span>Plantillas</span></a></li>
                            <li *ngIf="isAdmin() && config.menu.documentos.externos" class="nav-item"><a class="nav-link" (click)="openPage('/document/folder-extern-file')"><span>Documentos externos</span></a></li>
                            <li *ngIf="config.menu.documentos.request_changes" class="nav-item"><a class="nav-link" (click)="openPage('/document/document-request')"><span>Solicitud de cambios</span></a></li>
                            <li *ngIf="permissions['documentos.acceso_indicadores'] && config.menu.documentos.indicators" class="nav-item"><a class="nav-link" (click)="openPage('/document/indicators')"><span>Indicadores</span></a></li>
                            <li *ngIf="config.menu.documentos.masterlist && permissions['documentos.acceso_listado_maestro'] && config.menu.documentos.SIGCI" class="nav-item"><a class="nav-link" (click)="openPage('/document/master-list')"><span>Listado maestro</span></a></li>
                        </ul>
                    </div>
                </li>
                <li *ngIf="Permissions.audits_and_inspections && config.menu.auditorias" class="nav-item">
                    <a class="nav-link collapsed text-truncate" href="#audit-select" data-toggle="collapse" data-target="#audit-select">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/AuditWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/AuditGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline pr-3">Auditorías</span><i class="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                    <div class="collapse expandable-menu" id="audit-select" aria-expanded="false">
                        <ul class="flex-column nav sub-item">
                            <li class="nav-item"><a class="nav-link" (click)="openPage('/diary')"><span>Agenda</span></a></li>
                            <li class="nav-item"><a class="nav-link" (click)="openPage('/template-index')"><span>Plantilla</span></a></li>
                            <li class="nav-item"><a class="nav-link" (click)="openPage('/general-report')"><span>Informes</span></a></li>
                            <li class="nav-item"><a class="nav-link" (click)="openPage('/analysis')"><span>Análisis</span></a></li>
                            <!-- <li class="nav-item"><a class="nav-link" href="#"><span>Actions</span></a></li> -->
                        </ul>
                    </div>
                </li>
                <li *ngIf="Permissions.incident_management && config.menu.indicidentes" class="nav-item">
                    <a class="nav-link collapsed text-truncate" href="#incident-select" data-toggle="collapse" data-target="#incident-select">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/IncidentsWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/IncidentsGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline">Incidentes</span><i class="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                    <div class="collapse expandable-menu" id="incident-select" aria-expanded="false">
                        <ul class="flex-column nav sub-item">
                            <li class="nav-item"><a class="nav-link" href="#"><span>Actions</span></a></li>
                        </ul>
                    </div>
                </li>
                <li *ngIf="Permissions.administrative_management && config.menu.administracion" class="nav-item">
                    <a class="nav-link collapsed text-truncate" href="#administration-select" data-toggle="collapse" data-target="#administration-select">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/AdminWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/AdminGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline">Administración</span><i class="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                    <div class="collapse expandable-menu" id="administration-select" aria-expanded="false">
                        <ul class="flex-column nav sub-item">
                            <li class="nav-item"><a class="nav-link" href="#"><span>Actions</span></a></li>
                        </ul>
                    </div>
                </li>
                <li *ngIf="Permissions.continuous_improvement && config.menu.mejora_continua" class="nav-item">
                    <a class="nav-link collapsed text-truncate" href="#improvement-select" data-toggle="collapse" data-target="#improvement-select">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/ImproveWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/ImproveGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline">Mejora continua</span><i class="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                    <div class="collapse expandable-menu" id="improvement-select" aria-expanded="false">
                        <ul class="flex-column nav sub-item">
                            <li class="nav-item"><a class="nav-link" href="#"><span>Actions</span></a></li>
                        </ul>
                    </div>
                </li>
                <li *ngIf="Permissions.risk_management && config.menu.risks" class="nav-item">
                    <a class="nav-link collapsed text-truncate" href="#risk-select" data-toggle="collapse" data-target="#risk-select">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/RisksWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/RisksGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline mr-4">Riesgos</span><i class="fa fa-angle-down" aria-hidden="true"></i>
                    </a>
                    <div class="collapse expandable-menu" id="risk-select" aria-expanded="false">
                        <ul class="flex-column nav sub-item">
                            <li class="nav-item" (click)="openPage('/risk-management')"><a class="nav-link"><span>Análisis</span></a></li>
                            <li class="nav-item"  (click)="openPage('/haccp')"><a class="nav-link"><span>Planes HACCP</span></a></li>
                            <!-- <li class="nav-item"><a class="nav-link" href="#"><span>Actions</span></a></li> -->
                        </ul>
                    </div>
                </li>
                <li *ngIf="config.menu.bandeja" class="nav-item">
                    <a class="nav-link text-truncate" (click)="openPage('/notification-inbox')">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/MessageWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/MessageGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline">Bandeja de entrada</span>
                    </a>
                </li>
                <li *ngIf="permissions['usuarios.crear_roles'] && config.menu.roles" class="nav-item">
                    <a class="nav-link text-truncate" (click)="openPage('/role')">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon white-filter" src="../../../assets/resources/icons/person-outline.svg" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/person-outline.svg" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline">Roles</span>
                    </a>
                </li>
                <li *ngIf="permissions['usuarios.acceso_directorio'] && config.menu.directorio" class="nav-item">
                    <a class="nav-link text-truncate" (click)="openPage('/users-list')">
                        <img [ngStyle]="{'width': !mouseInContainer ? '30px' : '', 'margin-left': !mouseInContainer ? '10px' : ''}" class="menu-icon" src="../../../assets/resources/icons/DirectoryWhite.png" alt="">
                        <img class="green-icon" src="../../../assets/resources/icons/DirectoryGreen.png" alt="">
                        <span *ngIf="mouseInContainer" class="d-sm-inline">Directorio</span>
                    </a>
                </li>
            </div>
        </ul>
    </div>
</div>
