import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, map, Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import Swal from 'sweetalert2';
import { RoleService } from '../services/role.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EditGuard {
    user$: Observable<any> = this.userService.retrieveUser();

    constructor (
        private router: Router,
        private userService: UserService,
        private roleService: RoleService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            const user = await firstValueFrom<any>(this.user$);
            const permissions = await firstValueFrom<any>(this.roleService.getRoles({}));
            console.log(user);
            console.log(permissions);
            if (user && permissions) {
                if (user.role_id === 1) {
                    return true;
                } else {
                    const rolePermissions = permissions.data.filter(permission => permission.id === user.role_id);
                    const filteredPermissions = rolePermissions.length > 0 ? rolePermissions[0].permissions : [];
                    const path = state.url.split('/')[2];
                    console.log(path);
                    console.log(path.includes('approve'));
                    

                    switch (true) {
                        case path.includes('create'):
                            return filteredPermissions.some(permission => permission.code === 'documentos.crear_documentos_internos' || permission.code === 'documentos.crear_documentos_externos');
                        case path.includes('flow'):
                            return true;
                        case ['approve', 'diffusion', 'rejected'].some(el => path.includes(el)):
                            return true;
                        case path.includes('folder'):
                            return true;
                        default:
                            return false;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'No tienes permisos de edición de documentos',
                        text: 'Contacta al administrador para habilitarlos'
                    });
                    this.router.navigateByUrl('/home');
                    return false;
                }
            } else {
                console.log();
                
                return false;
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Contacta al administrador para habilitarlos'
            });
            return false;
        }
    }
}
